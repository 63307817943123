.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem!important;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.rotating-lines {
  position: absolute;
  z-index: 2;
  top: 30%;
  left: 50%;
}
