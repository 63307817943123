.menu-close {
	height: 0;
	-webkit-transition-timing-function: ease;
	transition-timing-function: ease;
	-webkit-transition-duration: .35s;
	transition-duration: .35s;
	-webkit-transition-property: height;
	transition-property: height;
}

.menu-open-1 {
	height: 37.34px;
	-webkit-transition-timing-function: ease;
	transition-timing-function: ease;
	-webkit-transition-duration: .35s;
	transition-duration: .35s;
	-webkit-transition-property: height;
	transition-property: height;
}

.menu-open-2 {
	height: calc(2*37.4px);
	-webkit-transition-timing-function: ease;
	transition-timing-function: ease;
	-webkit-transition-duration: .35s;
	transition-duration: .35s;
	-webkit-transition-property: height;
	transition-property: height;
}

.menu-open-3 {
	height: calc(3*37.34px);
	-webkit-transition-timing-function: ease;
	transition-timing-function: ease;
	-webkit-transition-duration: .35s;
	transition-duration: .35s;
	-webkit-transition-property: height;
	transition-property: height;
}

.menu-open-4 {
	height: calc(4*37.34px);
	-webkit-transition-timing-function: ease;
	transition-timing-function: ease;
	-webkit-transition-duration: .35s;
	transition-duration: .35s;
	-webkit-transition-property: height;
	transition-property: height;
}

.menu-open-6 {
	height: calc(6*37.34px);
	-webkit-transition-timing-function: ease;
	transition-timing-function: ease;
	-webkit-transition-duration: .35s;
	transition-duration: .35s;
	-webkit-transition-property: height;
	transition-property: height;
}

.menu-open-10 {
	height: calc(10*37.34px);
	-webkit-transition-timing-function: ease;
	transition-timing-function: ease;
	-webkit-transition-duration: .35s;
	transition-duration: .35s;
	-webkit-transition-property: height;
	transition-property: height
}

.metismenu2 li {
	overflow:hidden;
}
