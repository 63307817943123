tr td.done {
    color: #16a34a;
    font-weight: bold;
}

tr td.processing {
    color: #3b82f6;
    font-weight: bold;
}

tr td.error {
    color: #d23d24;
    font-weight: bold;
}

tr td.error a {
    text-decoration: none;
    color: #d23d24;
}

tr td.onvtserver a {
    text-decoration: none;
    color: #d23d24;
}