.canvas {
    top: 30px;
    max-width: 75%;
    height: 100%;
    left: 100px;
    margin: 0 auto;
    transition: all 0.5s ease-out;
    background-image: url("../../data/pitch.png");
    background-repeat: no-repeat;
    background-size: 100%;
    display: block;
}

.canvas .control {
    margin-top: 3px;
    width: 100%;
    display: flex;
}

.canvas .control .one {
    height: 100%;
    width: 17%;
}

.canvas .control .one button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 5px;
}

.canvas .control .two {
    height: 100%;
    max-width: 66%;
}

.canvas .control .two ul {
    list-style: none;
    display: flex;
    word-break: '';
    overflow-x: scroll;
}

.canvas .control .two ul li {
    width: 40px;
    background-color: white;
    text-align: center;
}

.canvas .control .two ul li a {
    cursor: pointer;
    display: block;
    width: 50px;
    padding: 5px;
}


.canvas .control .three {
    height: 100%;
    width: 17%;
}

.canvas .control .three button {
    border-radius: 50%;
}

.canvas #konva-container {
    text-align: center;
}

.canvas .button {
    position: relative;
}

.canvas .button .closeButton {
    position: absolute;
    top: -35px;
    left: 0;
    border: 0;
}



.canvas ul li a:hover {
    background-color: blue;
    color: white;
}

.canvas .button-player button {
    color: white;
    width: 100px;
    margin-right: 15px;
}

.canvas .button-player button i {
    margin-right: 3px;
}

.canvas .canvas-rotating {
    position: relative;
}

.canvas .canvas-rotating .rotating-lines {
    position: absolute;
    top: 285px;
    left: 480px
}