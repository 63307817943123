.picture {
  position: relative;
  /* background-image: url('../../data/pitch.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 1083px;
  height: 600px; */
}

img.footballField {
  pointer-events: none;
  z-index: -999;
}

.player {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  z-index: 10;
  background-color: red;
  /* transition-property: all;
  transition-duration: 1000ms; */
  
}
.notification {
  position: absolute;
  top:12rem;
  left:20rem;
  z-index:2;
  font-size: 15px;
  font-weight: 500;
  color:black;
}

.saiva-color {
  background-color : #3bffd8!important; 
  border-color: #2effd5!important;
  color:#3d4141!important;
}

.saiva-color-button {
  background-color : #3bffd8!important; 
  border-color: #2effd5!important;
  color:#3d4141!important;
  cursor: pointer;
}

.saiva-button {
  padding: 5px 10px;
  margin: 0;
  border: 0;
  font-size: 1.1rem;
  height: 35px;
  list-style: none;
  cursor: pointer;
  background-color : #D2F3ED!important; 
  border-color: #2effd5!important;
  color:#3d4141!important;
  transition: all 0.2s;
}

.saiva-button2 {
  padding: 5px 10px;
  margin: 0;
  border: 0;
  font-size: 1.1rem;
  height: 35px;
  list-style: none;
  cursor: pointer;
  background-color: #3BFED8!important;
  border-color: #2effd5!important;
  color: #000000!important;
  transition: all 0.2s;
}

.saiva-button-active {
  background-color: #3BFED8!important;
  transform: translate(2px, -2px);
  box-shadow: 4px 4px 10px rgb(0 0 0 / 0.3);
  color: #000000!important;
}

.saiva-button:hover{
  background-color: #3BFED8!important;
  transform: translate(2px, -2px);
  box-shadow: 4px 4px 10px rgb(0 0 0 / 0.3);
  color: #000000!important;
}

.saiva-std-btn {
  border: 1
}

.saiva-circle-button{
  background-color: #3BFED8!important;
  border-radius: 50%!important;
  padding: 0!important;
  width: 35px!important;
  height: 35px!important;
}

.click-header {
  cursor: pointer;
}
.shape-circle{
  width: 75px;
  height: 75px;
  position: relative;
  padding: 0;
}

.circle {
  background-color: #93c5fd;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
}

.circle:hover {
  background-color: #2563eb!important;
}

.circle-active {  
  background-color: #2563eb!important;
}

.circle-button-1 {
  left:32.5px
}
.circle-button-2 {
  top: 12.5%;
  right:12.5%
}
.circle-button-3 {
  right:0;
  top:37.5%
}
.circle-button-4 {
  bottom: 15.5%;
  right:12.5%
}
.circle-button-5 {
  top: 62.5px;
  left: 32.5px
}
.circle-button-6 {
  bottom: 15.5%;
  left:12.5%
}
.circle-button-7 {
  bottom: 32.5px;
  left:0
}
.circle-button-8 {
  left: 12.5%;
  top:12.5%
}

#stage-parent {
  width: 100%;
}

