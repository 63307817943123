.scenario-type .content {
    display: flex;
}

.type-small {
    display: none !important
}

.tracking-file .first-row, .tracking-file .second-row {
    display: flex;
}

.tracking-file .first-row .left, .tracking-file .second-row .left {
    padding-left: 0;
}

.tracking-file .second-row .time {
    display: flex;
}


@media (max-width: 1283px) {
    .scenario-type .content {
        flex-direction: column
    }

    .scenario-type .content .type {
        display: none;
        margin-top: 10px;
        position: relative;
        max-width: 100%;
    }

    .type-small {
        display: flex !important;
    }

    .scenario-type .content .source {
        max-width: 100%;
    }

    .scenario-type .content .type .type-content {
        padding-left: 0;
    }

    .scenario-type .content .type label {
        position: absolute;
        flex: 0 0 50%;
        max-width: 50%;
        top: 0;
        left: -39.5%;
    }

    .tracking-file .first-row {
        flex-direction: column;
    }

    .tracking-file .second-row {
        flex-direction: column;
    }

    .tracking-file .second-row > * {
        max-width: 100%;
    }

    .tracking-file .second-row label {
        max-width: 40%!important;
    }

    .tracking-file .first-row .right {
        margin-top: 10px;
        max-width: 100%;
    }

    .tracking-file .first-row .right, .tracking-file .second-row .right {
        padding-left: 0;
    }
}
@media (max-width:1024px){
    .tracking-file .first-row .right, .tracking-file .second-row .right {
        display: block;
    }
}