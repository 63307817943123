.loaded { display: none; }

#debug { padding-left: 280px; }

/* TAB View */
div.tabs {
	margin-bottom: 4px;
}

.w100 { width: 100px; }
.w150 { width: 150px; }
.w200 { width: 200px; }
.w250 { width: 250px; }
.w300 { width: 300px; }

/* forms */
.form-col-25 { float: left; width: 25%; max-width:200px; margin-top: 6px; }
.form-col-75 { float: left; width: 75%; margin-top: 6px; }

/* Clear floats after the columns */
.form-row:after {
    content: "";
    display: table;
    clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .col-25, .col-75, input[type=submit] {
        width: 100%;
        margin-top: 0;
    }
}

.form-container { /*background-color: #ddd; border-radius: 4px; border: 1px solid #ccc;*/}
.form-fieldset {
}
.form-row {clear: both;}

form { /*font-size: 0.9em;*/ }

fieldset {
/*    border: 1px solid #eee;*/
    border-radius: 5px;
    background-color: #f8f8f8;
    padding: 8px 20px;
/*    margin: 4px 0px;*/
}

.fullwidth-area {
    min-width: 100% !important;
    min-height: 300px !important;
    margin-top:6px;
}

input[type=submit], .button, .button-cancel, .button-right {
    float:left;
    background-color: #36ab3a;
    color: white;
    padding: 10px 14px;
    margin: 8px 8px 12px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
	text-decoration: none;
}

input[type=submit]:hover, .button:hover, .button-cancel:hover, .button-right:hover {
    background-color: #3d8a40;
    color: white;
	text-decoration: none;
}
input[type=submit].button-cancel,.button-cancel { background-color: #d23d24; }
input[type=submit].button-right,.button-right { float:right; margin: 8px 0 12px 8px; }
input[type=submit].button-cancel:hover,.button-cancel:hover {  background-color: #b0341f; }

.form-control { padding: 4px 8px; height: auto; }
.form-control, select.form-control:not([size]):not([multiple]) {
    height:calc(1.8725rem + 2px)
}
.form-group { margin-bottom: 8px; }

/* embedded is for use in tables */
.form-embedded {
	display: inline-block;
	float: none;
}
.form-embedded .icon-button {
	margin-right: -3px;
}

.icon-button {
    display: inline-block;
    background-color: #888;
    color: white;
    border-radius: 4px;
    padding: 4px;
    margin: 0 2px;
    border: none;
	min-width: 20px;
	text-align: center;
	text-decoration: none;
}

.icon-button:hover {
    background-color: #666;
}

.help {
    border-bottom: 1px dashed #888;
}

/* helpers. are they in use? */
.color-green-bg { background-color: #36ab3a; }  .color-green-bg:hover { background-color: #3d8a40; }
.color-grey-bg { background-color: #aaa; }      .color-grey-bg:hover { background-color: #888; }
.color-orange-bg { background-color: #d7901f; }    .color-orange-bg:hover { background-color: #c0811d; }
.color-blue-bg { background-color: #4085cc; }      .color-blue-bg:hover { background-color: #3b79b9; }
.color-red-bg { background-color: #d23d24; }       .color-red-bg:hover { background-color: #b0341f; }
.color-teal-bg { background-color: #12d1d8; }      .color-teal-bg:hover { background-color: #14b2b8; }
.color-blue { color: #4085cc; }      .color-blue:hover { color: #3b79b9; }

.form-sep-top { padding-top: 0; }
.form-sep-small { padding: 0; margin: 0; }
.form-sep { padding-top: 16px; text-decoration: underline; font-weight:bold; }

.form-date select { width: 72px; margin-right: 1px; }
.form-datepicker input { width: 203px; margin-right: 1px; }

.folder { color: #ee0; padding-right: 8px; }


/* file manage only ? */
.fancybox-slide--iframe .fancybox-content {
    width  : 800px;
    height : 600px;
    max-width  : 80%;
    max-height : 80%;
    margin: 0;
}

.tinymce-content p {
    padding: 0;
    margin: 2px 0;
}

/* tables */
table {
    width: 100%;
    border-collapse: collapse;
}
form table {
    font-size: 1.1em;
}

.table thead {
	background: #f1f3fa;
}
.table th {
	padding: 8px;
    border: 1px solid #e7e9f3;
	border-bottom: 2px solid #e7e9f3;
}
.table tbody tr:nth-child(even) {background: #f8f8f8}
.table tbody tr:nth-child(odd) {background: #fff}

.table tbody tr:nth-child(even):hover {background: #daedfc}
.table tbody tr:nth-child(odd):hover {background: #eaf6ff}

.settings td { padding: 4px; }
.table td { border: 1px solid #eef2f7; padding: 4px 8px; }

div.table-action { float: left; }
div.table-action select { font-size: 12px; }

.quickmenu div {
	margin: -10px 0 4px 9px;
}

/*
	Pagination
*/
.pagination {
	padding-top: 2px;
	font-size: 0.9em;
	float: right;
	font-weight: bold;
}
.pagination span { text-decoration: none; padding: 0 2px; }
.pagination a { text-decoration: none;  padding: 0 2px; }
.pagination a:hover { text-decoration: none; }

.pag-active,
.pag-prev,
.pag-next {
	
}
