/* Unity Frame Styles */
.unity-frame-container {
    width: 90%;
    max-width: 1060px;
    aspect-ratio: 16 / 10;
    margin: 20px auto;
    position: relative;
    padding: 15px;
    border: 2px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

/* Loading Container */
.unity-loading-container {
    text-align: center;
    margin: 20px auto;
}

/* Loading Message */
.unity-loading-message {
    font-family: "Arial", sans-serif;
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
}

/* Downloading Message */
.unity-downloading-message {
    font-family: "Arial", sans-serif;
    font-size: 14px;
    color: #777;
}

/* Fullscreen Button */
.unity-fullscreen-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.unity-fullscreen-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.unity-fullscreen-button:hover {
    background-color: #0056b3;
}

/* Unity Element */
.unity-element {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .unity-frame-container {
        padding: 10px;
        border-radius: 8px;
    }

    .unity-fullscreen-button {
        font-size: 12px;
        padding: 8px 16px;
    }

    .unity-loading-message {
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .unity-frame-container {
        width: 100%;
        padding: 8px;
    }

    .unity-fullscreen-button {
        width: 100%;
        text-align: center;
    }

    .unity-loading-message {
        font-size: 12px;
    }

    .unity-downloading-message {
        font-size: 12px;
    }
}
