.image {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    width: 100%;
}

.image img {
    max-width: 100%; /* Ensures image scales down on smaller screens */
    height: auto; /* Maintains aspect ratio */
    width: 450px; /* Default width, adjust as needed */
}

.login-title {
    font-size: 25px; /* Large font */
    font-weight: bold; /* Make it bold */
    color: #333; /* Dark color for readability */
    text-align: center; /* Center the text */
}

.login-container {
    width: 500px; /* Slimmer box */
    max-width: 90%;
    padding: 25px;
    background: white;
    border-radius: 12px; /* Rounded corners */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Modern shadow */
    transition: all 0.3s ease-in-out;
}

.login-container:hover {
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15); /* Smooth hover effect */
}

.login-container .form-control {
    border-radius: 8px; /* Soft edges for input */
    border: 1px solid #ddd;
    padding: 10px 15px;
    font-size: 14px;
    transition: all 0.3s ease-in-out;
}

.login-container .form-control:focus {
    border-color: #a22a29;
    box-shadow: 0px 0px 8px rgba(162, 42, 41, 0.3); /* Slight glow effect */
}

.login-container .btn-primary {
    background-color: #a22a29 !important;
    border: none;
    border-radius: 8px;
    padding: 10px 15px;
    font-size: 16px;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
}

.login-container .btn-primary:hover {
    background-color: #871e1d !important;
    box-shadow: 0px 4px 12px rgba(162, 42, 41, 0.4);
    transform: translateY(-2px); /* Subtle lift on hover */
}

.login-container .form-check-input {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.login-container .form-check-label {
    font-size: 14px;
}

/* Background for modern feel */
.login-page {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to right, #f7f7f7, #ececec); /* Light gradient */
}


.login-page {
    min-height: 100vh;
    display: flex;
}
.login-page .left {
    padding: 40px;
    width: 500px;
}

.login-page .left .box-content{
    padding: 30px;
}

.login-page .left .image {
    margin-bottom: 200px;
}

.login-page .left .sign-text {
    margin-bottom: 50px;
}

.login-page .left .sign-text .main{
    font-weight: bold;
    font-size: 1.1rem;
}

.login-page button{
    margin-top: 30px;
}

.login-container label {
    display: block; /* Ensure labels take full width */
    text-align: left; /* Align text to the left */
    width: 100%; /* Make sure it stretches across */
}


.form-check {
    display: flex;
    align-items: center; /* Align items vertically */
    justify-content: flex-start; /* Align content to the left */
}

.form-check-input {
    margin-right: 5px; /* Add space between checkbox and label */
}


.btn-primary {
    background-color: #a22a29 !important;
    border-color: #a22a29 !important;
}

.btn-primary:hover {
    background-color: #871e1d !important; /* Slightly darker shade for hover effect */
    border-color: #871e1d !important;
}


.login-page {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/images/bg-auth-1-2.png'); /* Add the path to your image */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


.login-page .right .blur {
    width: 40%;
    height: 50%;
    /* background-color: black; */
    backdrop-filter: blur(45px);
}

@media screen and (max-height: 800px){
    .login-page .left .image {
        margin-bottom: 60px;
    }

    .login-page .left .sign-text {
        margin-bottom: 10px;
    }
}
@media screen and (max-height: 600px){
    .login-page .left .image {
        margin-bottom: 20px;
    }
}


@media screen and (max-width: 1024px){
    .right {
        display: none;
    }

    .login-page .left {
       width: 100%;
       margin: 0 auto;
    }

    .login-page .left .image {
        text-align: center;
    }
}
